import React from "react";
import Tutor2 from "../../../assets/Tutor2.svg";
import pencil2 from "../../../assets/pencil2.svg";
import bulb2 from "../../../assets/bulb2.svg";
import calculater from "../../../assets/calculater.svg";
import DottedArrow from "../../../assets/DottedArrow.svg";
import line2 from "../../../assets/line2.svg";
import line3 from "../../../assets/line3.svg";
import Group2081 from "../../../assets/Group2081.svg";
import Group35 from "../../../assets/Group35.svg";
import Group36 from "../../../assets/Group36.svg";
import arrow2 from "../../../assets/arrow2.svg";
import Scale from "../../../assets/Scale.svg";
import Frame from "../../../assets/Frame.svg";
import Leaderstyle from "../../../assets/Leaderstyle.svg";
import Leaderstyle2 from "../../../assets/Leaderstyle2.svg";
import Leaderstyle3 from "../../../assets/Leaderstyle3.svg";
import Pin1 from "../../../assets/Pin1.svg";
import Pin2 from "../../../assets/Pin2.svg";
import Pin3 from "../../../assets/Pin3.svg";
import Frame24 from "../../../assets/Frame24.svg";
import Frame25 from "../../../assets/Frame25.svg";
import GooglePlay from "../../../assets/GooglePlay.svg";
// import AppStore from "../../../assets/AppStore.svg";
import location2 from "../../../assets/location2.svg";
import Phone from "../../../assets/Phone.svg";
import Email from "../../../assets/Email.svg";
import Group92 from "../../../assets/Group92.svg";
import LogoHeader from "../../../assets/LogoHeader.svg";
import Group21 from "../../../assets/Group21.png";
import LogoFooter from "../../../assets/LogoFooter.svg";
import GroupPhone from "../../../assets/mobile-screen 2.svg";
import PhoneDoodlePlus from "../../../assets/PhoneDoodlePlus.svg";
import PhoneDoodleMultiply from "../../../assets/PhoneDoodleMultiply.svg";
import PhoneDoodleBook from "../../../assets/PhoneDoodleBook.svg";
import PhoneDoodleDivide from "../../../assets/PhoneDoodleDivide.svg";
import FooterBack from "../../../assets/FooterBack.svg";
import Group2110 from "../../../assets/Group2110.svg";
import Group2111 from "../../../assets/Group2111.svg";
import Group221 from "../../../assets/Group221.svg";
import NoteBook from "../../../assets/NoteBook.svg";
import Globe from "../../../assets/Globe.svg";
import Step1 from "../../../assets/Step1.svg";
import Step2 from "../../../assets/Step2.svg";
import Step3 from "../../../assets/Step3.svg";
import line4 from "../../../assets/line4.svg";
import cloudshape from '../../../assets/cloudshape.svg'
import teacherteaching from '../../../assets/teacherteaching.svg'
import Stack from "@mui/material/Stack";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { styled, css } from "@mui/system";
import { Modal as BaseModal } from "@mui/base/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Container, Grid, Typography, Button } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import axios from "axios"
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import { yupResolver } from "@hookform/resolvers/yup";
import Footer from "./Footer";
import { Link, useNavigate } from "react-router-dom";
const themeColors = ["#ff5733", "#3498db", "#2ecc71"];

const Landing = () => {
  const navigate = useNavigate();
  const schema = yup.object().shape({
    email: yup.string().email().required(),
    fullName: yup.string().required(),
    pincode: yup.string().required(),
    area: yup.string().required(),
    city: yup.string().required(),
    mobileNumber: yup.string().required(),
    subject: yup.string().required(),
    course: yup.string().required(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      classMode: "2",
    },
  });



  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = (reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false)
    }
  }
  const [selectedColor, setSelectedColor] = useState("");
  const [loading, setLoading] = useState(false);

  const onSubmitHandler = async (data) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("email", data.email);
    formData.append("area", data.area);
    formData.append("full_name", data.fullName);
    formData.append("pincode", data.pincode);
    formData.append("course", data.course);
    formData.append("subject", data.subject);
    formData.append("class_mode", data.classMode);
    formData.append("city", data.city);
    formData.append("phone", data.mobileNumber);

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    try {
      const response = await axios.post(
        "https://kanhahometutions.com/api/demo_request_new.php",
        formData,
        config
      );

      if (response.status === 200) {
        toast.success(response.data.message);
        setLoading(false);
        setOpen(false);
        reset();
      } else {
        toast.warning(response.data.message ?? "Something Went Wrong");
        setLoading(false);
      }
    } catch (error) {
      toast.error(
        error?.response.data.message ?? "An error occurred. Please try again."
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * themeColors.length);
    setSelectedColor(themeColors[randomIndex]);
  }, []);



  const sxStyle = {
    display: 'flex',
    justifyContent: { xs: 'center' },
    alignItems: 'center',
  };

  // const studentLogin = () =>{
  //   navigate('http://localhost:3000/student-signin')
  // }

  //Get the button

// When the user clicks on the button, scroll to the top of the document

  const isXs = useMediaQuery('(min-width:600px)');
  
  const markerStyle = {
    color: '#B64502',
  } 
  return (
    <>
      {/* Section1 */}
      <Grid container style={{ position: "relative", backgroundColor: isXs ? 'transparent' : '#CB8B65' }} sx={sxStyle}>

      
        <svg
          width="1555"
          height="654"
          viewBox="0 0 1555 654"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "auto",
            zIndex: -1,
            display: isXs ? '' : 'none',
          }}
          
        >
          <g filter="url(#filter0_i_223_6379)">
            <path
              d="M363.509 653.318C86.7194 655.131 6.15154 495.411 0.466309 415.325V0.536621L1554.09 0.536972V156.894V589.99C1554.09 589.99 1473.53 543.105 1332.29 549.935C1238.05 554.493 1106.22 626.591 992.133 592.12C707.06 505.989 709.496 651.051 363.509 653.318Z"
              fill="#CB8B65"
            />
          </g>
          <defs>
            <filter
              id="filter0_i_223_6379"
              x="0.466309"
              y="-18.4634"
              width="1553.63"
              height="671.797"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="-19" />
              <feGaussianBlur stdDeviation="10" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_223_6379"
              />
            </filter>
          </defs>
        </svg>
        <img src={Group21} style={{ position: 'absolute', bottom: '-18%', display: isXs ? '' : 'none', zIndex: -1, maxWidth: '100%' }} />
        <img src={pencil2} style={{ position: 'absolute', left: '0%', top: '5%', display: isXs ? 'none' : '' }} />
        <img src={bulb2} style={{ position: 'absolute', right: '0%', top: '15%', display: isXs ? 'none' : '' }} />
        <img src={calculater} style={{ position: 'absolute', bottom: '0%', left: '5%', display: isXs ? 'none' : '' }} />
        <img src={Scale} style={{ position: 'absolute', bottom: '0%', right: '10%', width: '20%', display: isXs ? 'none' : '' }} />

        <Grid item xs={10} sm={5} md={6} lg={6} xl={6} xxl={6} >
          <div>
            <img src={LogoHeader} alt="logoheader"
              style={{ width: isXs ? '25%' : '60%', marginLeft: isXs ? "15%" : '20%' }} />
            <div className={isXs ? '' : 'text-center'} style={{ marginLeft: isXs ? "15%" : '' }}>
              <div style={{ lineHeight: "1" }}>
                <div className="text-white" style={{ fontSize: isXs ? "1.5vw" : '3.8vw', marginTop: isXs ? '2%' : '4%' }}>
                  Welcome to Kanha Home Tutions
                </div>
                <div className="text-white fw-bold"
                  style={{ fontSize: isXs ? "3vw" : '6.6vw', marginTop: isXs ? '3%' : '65%' }}>
                  Discover Your
                </div>
              </div>
              <div className="text-white fw-bold"
                style={{ fontSize: isXs ? "3vw" : '6.6vw' }}>
                Perfect Learning Partner
              </div>
              <img src={line2} style={{ width: "25%", maxWidth: "200px" }} />
              <div
                className="text-white mt-2"
                style={{ width: "100%", fontSize: isXs ? "1vw" : '3.5vw' }}
              >
                Connect with expert tutors tailored to fit your academic needs
                and personal learning style.
              </div>
              <button
                className="py-2 px-2"
                onClick={handleOpen}
                type="button"
                style={{
                  backgroundColor: "#FFDB7E",
                  border: "none",
                  color: "#866D2C",
                  borderRadius: "5px",
                  fontWeight: "bold",
                  marginTop: isXs ? '5%' : '10%',
                  fontSize: isXs ? "1.1vw" : '4.5vw',
                  width: isXs ? '35%' : '75%',
                  boxShadow: '5px  5px 2px rgb(0,0,0, .2)'
                }}
              >
                Get Started Today
                <img
                  src={DottedArrow}
                  style={{
                    width: "14%",
                    maxWidth: "25px",
                    marginLeft: "5%",
                  }}
                />
              </button>
            </div>
          </div>
        </Grid>
        <Grid item sm={12} md={6} lg={6} xl={6} xxl={6} >
          <div style={{display: "flex", flexDirection: isXs ? "row" : "column" , gap: "1rem" , position: "absolute" , top: isXs ?  "2rem" : "8rem" , left: isXs ?  "55rem" : "6rem"}}>
            <button style={{width: "11rem", height: "3.5rem" ,border: "3px solid white" , backgroundColor: "rgb(255,255,255 ,.1)" , color: "white" , borderRadius: "20rem" , fontSize: "1rem", fontWeight: "500" , boxShadow: "4px 7px rgb(000,000,000 ,.1)  "}}><Link to="https://tutor.kanhahometutions.com/student-signin" style={{color: "white" ,       textDecoration: "none"  // This removes the underline
}}>Login as Student</Link></button>
            <button style={{width: "11rem", height: "3.5rem" ,border: "3px solid white" , backgroundColor: "rgb(255,255,255 ,.1)" , color: "white" , borderRadius: "20rem" , fontSize: "1rem", fontWeight: "500" , boxShadow: "4px 7px rgb(000,000,000 ,.1)  "}}><Link to="https://tutor.kanhahometutions.com/signin-teacher" style={{color: "white" , textDecoration:'none'}}>Login as Tutor</Link></button>
           
          </div>
          <div style={{marginTop: "0rem"}}>
            <img src={Tutor2} style={{ width: isXs ? '100%' : '100%', marginTop: isXs ? '20%' : '10%', marginBottom: isXs ? '' : '8%' }} />
          </div>
        </Grid>

      </Grid>

      {/* Section2 */}
      <Grid container style={{ position: "relative", backgroundColor: isXs ? 'transparent' : '#F6EBDC' }} sx={sxStyle}>

        <svg
          width="1549"
          height="1041"
          viewBox="0 0 1549 1041"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            position: "absolute",
            top: isXs ? '-25%' : '0%',
            left: 0,
            width: "100%",
            height: "auto",
            zIndex: -2,
            display: isXs ? '' : 'none'
          }}
        >
          <path
            d="M1548.23 0.310059H0.0720825V872.674C81.19 1035.96 492.48 983.73 773.672 956.998C979.637 937.417 1321.45 930.627 1548.23 1040.54V0.310059Z"
            fill="#F6EBDC"
          />
        </svg>
        <img src={Group2110} style={{ position: 'absolute', zIndex: -1, bottom: '-20%', display: isXs ? '' : 'none', width: '98%' }} />
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} >
          <div className="d-flex justify-content-center">
            <div className="text-center" style={{ marginTop: isXs ? "15%" : '12%' }}>
              <div
                style={{
                  color: "#A6653F",
                  fontSize: isXs ? "3vw" : '6vw',
                  fontWeight: "bold",
                }}
              >
                Looking for Home Tutors?
              </div>
              <img src={Group2081} style={{ width: isXs ? "80%" : '90%', marginTop: isXs ? "10%" : '12%' }} />
              <div className="text-center" style={{ marginTop: isXs ? "10%" : '12%', marginBottom: isXs ? '' : '40%' }}>
                <button
                  className="py-2"
                  onClick={handleOpen}
                  type="button"
                  style={{
                    backgroundColor: "#FFDB7E",
                    width: isXs ? "60%" : '90%',
                    border: "1px solid #CB8B65",
                    color: "#866D2C",
                    fontSize: isXs ? "1.2vw" : '4.5vw',
                    fontWeight: "bold",
                    boxShadow: '5px  5px 2px rgb(0,0,0, .2)'
                  }}
                >
                  I NEED A HOME/ONLINE TUTOR
                  <img
                    src={arrow2}
                    style={{
                      width: "9%",
                      maxWidth: "30px",
                      marginLeft: isXs ? "8%" : '3%',
                    }}
                  />
                </button>
                <img src={Group35} style={{ position: 'absolute', bottom: '0%', left: '5%', display: isXs ? 'none' : '' }} />
                <img src={Group36} style={{ position: 'absolute', bottom: '0%', right: '5%', display: isXs ? 'none' : '' }} />
                <Modal
                  aria-labelledby="unstyled-modal-title"
                  aria-describedby="unstyled-modal-description"
                  open={open}
                  onClose={handleClose('backdropClick')}
                  slots={{ backdrop: StyledBackdrop }}
                  sx={{ overflow: isXs ? "" : "auto" }}
                >
                  <div className="modal-content" style={{ marginTop: isXs ? '' : '55%', textAlign: isXs ? '' : 'center', width: isXs ? '40%' : '90%' }}>
                    <Button style={{ position: 'absolute', right: '0%', backgroundColor: 'white', borderRadius: '100%', minWidth: '2rem', color: 'black' }} onClick={() => handleClose('close')}>X</Button>
                    <div
                      className="text-center py-2 fw-bold"
                      style={{
                        backgroundColor: '#CB8B65',
                        width: "100%",
                        fontSize: isXs ? "28px" : "22px",
                        marginBottom: "5%",
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '0.5rem',
                        borderTopLeftRadius: '14px',
                        borderTopRightRadius: '14px'
                      }}
                    >
                      <div
                        style={{
                          color: "#FFDB7E",

                        }}
                      >
                        FREE
                      </div>
                      <div
                        style={{
                          color: "white",
                        }}
                      >
                        REGISTRATION
                      </div>

                    </div>
                    {/* <img src={Group92} style={{display: isXs ? "" : ""}}/> */}
                    <form onSubmit={handleSubmit(onSubmitHandler)}>
                      <Box
                        className="d-flex justify-content-center mt-2"
                        component="form"
                        sx={{
                          "& .MuiTextField-root": {
                            m: 1,
                            width: isXs ? "23ch" : "75%",
                          },
                          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          {
                            borderWidth: "0.5px",
                          },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <Grid container>
                          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                            <div style={{ textAlign: isXs ? 'end' : "center" }}>
                              <TextField
                                {...register("fullName")}
                                name="fullName"
                                id="fullName"
                                label="Full Name"
                                type="text"
                                placeholder="Enter your Full Name"
                                required
                                inputProps={{
                                  style: {
                                    height: "10px",
                                    fontSize: "12px",
                                  },
                                }}
                                InputLabelProps={{
                                  sx: { color: "black" },
                                }}
                                color="grey"
                                focused
                              // onChange={(e) =>
                              //     setFullName(e.target.value)
                              // }
                              />
                              <p style={{ fontSize: "12px", color: "red", marginRight: isXs ? '12%' : '' }}>
                                {errors.fullName?.message}
                              </p>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                            <div>
                              <TextField
                                {...register("mobileNumber")}
                                name="mobileNumber"
                                id="mobileNumber"
                                label="Mobile Number"
                                type="number"
                                placeholder="Enter your Mobile Number"
                                required
                                inputProps={{
                                  style: {
                                    height: "10px",
                                    fontSize: "12px",
                                  },
                                }}
                                InputLabelProps={{
                                  sx: { color: "black" },
                                }}
                                color="grey"
                                focused
                              // onChange={(e) =>
                              //     setMobileNumber(e.target.value)
                              // }
                              />
                              <p style={{ fontSize: "12px", color: "red", marginLeft: isXs ? '5%' : '' }}>
                                {errors.mobileNumber?.message}
                              </p>
                            </div>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box
                        className="d-flex  mt-1 justify-content-between "
                        component="form"
                        sx={{
                          "& .MuiTextField-root": {
                            m: 1,
                            width: isXs ? "23ch" : "75%",
                          },
                          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          {
                            borderWidth: "0.5px",
                          },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <Grid container>
                          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                            <div style={{ textAlign: isXs ? 'end' : "center" }}>
                              <TextField
                                {...register("email")}
                                name="email"
                                id="email"
                                label="Email"
                                type='"email'
                                placeholder="Enter your Email"
                                required
                                inputProps={{
                                  style: {
                                    height: "10px",
                                    fontSize: "12px",
                                  },
                                }}
                                InputLabelProps={{
                                  sx: { color: "black" },
                                }}
                                color="grey"
                                focused
                                style={{ marginLeft: "3%" }}
                              // onChange={(e) =>
                              //     setEmail(e.target.value)
                              // }
                              />
                              <p
                                style={{
                                  fontSize: "12px",
                                  color: "red",
                                  marginRight: isXs ? '20%' : ''
                                }}
                              >
                                {errors.email?.message}
                              </p>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                            <FormControl
                              component="fieldset"
                              style={{ marginLeft: "4%" }}
                            >
                              <FormLabel
                                component="legend"
                                style={{
                                  marginBottom: "0%",
                                  fontSize: isXs ? "0.9vw" : "3vw",
                                  color: "black",
                                  letterSpacing: "0px",
                                }}
                              >
                                Mode of Classes*
                              </FormLabel>
                              <Controller
                                // rules={{ required: true }}
                                control={control}
                                name="classMode"
                                render={({ field }) => (
                                  <RadioGroup
                                    row
                                    aria-label="position"
                                    {...field}
                                    name="position"
                                  >
                                    <FormControlLabel
                                      value="2"
                                      control={<Radio color="primary" />}
                                      label={
                                        <span
                                          style={{
                                            fontSize: isXs ? "1vw" : "3vw",
                                          }}
                                        >
                                          Online
                                        </span>
                                      }
                                    />
                                    <FormControlLabel
                                      value="1"
                                      control={<Radio color="primary" />}
                                      label={
                                        <span
                                          style={{
                                            fontSize: isXs ? "1vw" : "3vw",
                                          }}
                                        >
                                          Offine
                                        </span>
                                      }
                                    />
                                  </RadioGroup>
                                )}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box
                        className="d-flex justify-content-center mt-1"
                        component="form"
                        sx={{
                          "& .MuiTextField-root": {
                            m: 1,
                            width: isXs ? "23ch" : "75%",
                          },
                          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          {
                            borderWidth: "0.5px",
                          },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <Grid container>
                          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                            <div style={{ textAlign: isXs ? 'end' : "center" }}>
                              <TextField
                                name="ccb"
                                id="ccb"
                                label="Course / Class/ Board"
                                type='"text'
                                placeholder="Enter Course You Need"
                                {...register("course")}
                                required
                                inputProps={{
                                  style: {
                                    height: "10px",
                                    fontSize: "12px",
                                  },
                                }}
                                InputLabelProps={{
                                  sx: { color: "black" },
                                }}
                                color="grey"
                                focused
                              // onChange={(e) =>
                              //     setCource(e.target.value)
                              // }
                              />
                              <p style={{ fontSize: "12px", color: "red", marginRight: isXs ? '17%' : '' }}>
                                {errors.course?.message}
                              </p>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                            <div>
                              <TextField
                                name="subject"
                                {...register("subject")}
                                id="subject"
                                label="Subject"
                                type="text"
                                placeholder="Enter Subject You Need"
                                required
                                inputProps={{
                                  style: {
                                    height: "10px",
                                    fontSize: "12px",
                                  },
                                }}
                                InputLabelProps={{
                                  sx: { color: "black" },
                                }}
                                color="grey"
                                focused
                              // onChange={(e) =>
                              //     setSubject(e.target.value)
                              // }
                              />
                              <p style={{ fontSize: "12px", color: "red", marginLeft: isXs ? '5%' : '' }}>
                                {errors.subject?.message}
                              </p>
                            </div>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box
                        className="d-flex justify-content-center mt-1"
                        component="form"
                        sx={{
                          "& .MuiTextField-root": {
                            m: 1,
                            width: isXs ? "13.8ch" : "75%",
                          },
                          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          {
                            borderWidth: isXs ? "2px" : "2px",
                          },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <Grid container >
                          <Grid item xs={12} sm={4} md={4} lg={4} xl={4} xxl={4}>
                            <div style={{ textAlign: isXs ? 'end' : "center" }}>
                              <TextField
                                {...register("city")}
                                name="city"
                                id="city"
                                label="City"
                                type='"text'
                                placeholder="Enter City Name"
                                required
                                inputProps={{
                                  style: {
                                    height: "10px",
                                    fontSize: "12px",
                                  },
                                }}
                                InputLabelProps={{
                                  sx: { color: "black" },
                                }}
                                color="grey"
                                focused
                              // onChange={(e) =>
                              //     setCity(e.target.value)
                              // }
                              />
                              <p style={{ fontSize: "12px", color: "red" }}>
                                {errors.city?.message}
                              </p>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} lg={4} xl={4} xxl={4}>
                            <div style={{ textAlign: 'center' }}>
                              <TextField
                                {...register("area")}
                                name="area"
                                id="area"
                                label="Area"
                                type="text"
                                placeholder="Enter Area Name"
                                required
                                inputProps={{
                                  style: {
                                    height: "10px",
                                    fontSize: "12px",
                                  },
                                }}
                                InputLabelProps={{
                                  sx: { color: "black" },
                                }}
                                color="grey"
                                focused
                              // onChange={(e) =>
                              //     setArea(e.target.value)
                              // }
                              />
                              <p style={{ fontSize: "12px", color: "red" }}>
                                {errors.area?.message}
                              </p>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} lg={4} xl={4} xxl={4}>
                            <div tyle={{ textAlign: 'start' }}>
                              <TextField
                                {...register("pincode")}
                                name="pincode"
                                id="pincode"
                                label="Pincode"
                                type="text"
                                placeholder="Enter Pincode"
                                required
                                inputProps={{
                                  style: {
                                    height: "10px",
                                    fontSize: "12px",
                                  },
                                }}
                                InputLabelProps={{
                                  sx: { color: "black" },
                                }}
                                color="grey"
                                focused
                              // onChange={(e) =>
                              //     setPincode(e.target.value)
                              // }
                              />
                              <p style={{ fontSize: "12px", color: "red" }}>
                                {errors.pincode?.message}
                              </p>
                            </div>
                          </Grid>
                        </Grid>
                      </Box>
                      <div className="d-flex justify-content-center my-2">
                        {/* <button type="submit" className="btn btn-primary py-2 w-75 fw-bold" style={{ fontSize: '14px', border: '1px solid #CB8B65', backgroundColor: '#FFDB7E', color: '#866D2C', borderRadius: '0', zIndex: 3 }}>Get Free Demo Class</button> */}

                        {loading ? (
                          <Stack
                            sx={{ color: "grey.500" }}
                            spacing={2}
                            direction="row"
                          >
                            <CircularProgress color="success" />
                          </Stack>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-primary py-2 w-75 fw-bold"
                            style={{
                              fontSize: "14px",
                              border: "1px solid #CB8B65",
                              backgroundColor: "#FFDB7E",
                              color: "#866D2C",
                              borderRadius: "0",
                            }}
                          >
                            Get Free Demo Class
                          </button>
                        )}
                      </div>
                    </form>
                    <div className="d-flex flex-row justify-content-between w-full mb-3">
                      <div
                        className="flex-grow-1 text-center"
                        style={{
                          fontSize: "12px",
                          fontWeight: "900",
                        }}
                      >
                        <small className="block">
                          <span className="">By signing up you agree to our </span>
                          <a href='/terms-conditions' style={{ color: "#0582D2" }}>
                            {" "}
                            Terms and Conditions
                          </a>
                        </small>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
        </Grid>

      </Grid>

      {/* Section3 */}
      <Grid container sx={sxStyle} style={{ marginTop: isXs ? '10%' : '10%' }}>

        <Grid item xs={10} sm={5} md={6} lg={6} xl={6} xxl={6} >
          <div style={{ marginLeft: isXs ? '5%' : '', textAlign: isXs ? '' : 'center' }}>
            <div style={{ fontSize: isXs ? '2.2vw' : "7vw", fontWeight: "bold" }}>
              Experienced Tutors for Personal Tuitions
            </div>
            <div
              style={{
                color: "#CB8B65",
                marginTop: "3%",
                fontSize: isXs ? "1.5vw" : "4vw",
              }}
            >
              Your Gateway to Personalized Education
            </div>
            <div
              style={{
                marginTop: isXs ? "1%" : "10%",
                fontSize: isXs ? "1.1vw" : "2.5",
                textAlign: "justify",
              }}
            >
              Unlock the full potential of personalized education with our
              experienced tutors, who are readily available to deliver tailored
              learning experiences right at your doorstep or online. At Kanha
              Home Tutions, we understand the importance of matching you with
              the perfect tutor to meet your specific educational needs. Our
              tutors are not only experts in their respective fields but also
              skilled in delivering engaging and effective lessons, whether
              online or in-person. Enjoy the convenience of flexible scheduling
              and affordable rates, ensuring that you can access top-quality
              education from the comfort of your home, no matter where you are
              or what your learning goals might be. Choose Kanha Home Tutions
              for a reliable, personalized learning journey that caters to all
              subjects and preparation for any exam or board.
            </div>
          </div>
        </Grid>

        
        <Grid item xs={10} sm={5} md={6} lg={6} xl={6} xxl={6} style={{ textAlign: 'center' }}>
          <img
            src={Group2111}
            style={{
              width: isXs ? "70%" : "95%",
              marginTop: isXs ? '10%' : '15%'
            }}
          />
        </Grid>

        <Box sx={{position: "relative" , marginTop: "3%"}}>
          <img src={cloudshape} alt="" style={{width: isXs ? "100%" : "20rem" , minHeight: isXs ? "0%" : "80rem"}} />
        <div
                style={{
                  color: "#A6653F",
                  fontSize: isXs ? "2.5vw" : '6vw',
                  fontWeight: "bold",
                  textAlign: "center",
                  position: "absolute",
                  top: "6rem",
                  left: "30%",
                  right: "30%"
                }}
              >
                How Can Tutors Benefit?
              </div>

              <Box sx={{position: "absolute" , top: "10rem" , left: "15%" , right: "10%" , display: "flex" , justifyContent: "space-between" , gap: "5rem" , marginTop: "2rem"}}>
                <Box><img src={teacherteaching} alt="" style={{width: isXs ? "18rem" : "15rem"}} /></Box>
                <Box sx={{display: "flex" , flexDirection: "column" , gap: "2rem"}}> 
                  <Typography style={markerStyle} sx={{fontFamily: "jost" , width: "90%" , textAlign: "justify" }}>Kanha Home Tuitions helps tutors quickly access various leads for tuition services and connect with multiple academic institutions and students through a single portal. Be a home tutor, teach at educational institutions, or from your own place, all from one place.</Typography>
                  <Typography sx={{fontFamily: "jost"}}>
                    <ul style={{listStyleType: "circle" , textAlign: "left" , lineHeight: "2rem"}}>
                      <li>Contact parent directly.</li>
                      <li>No commission from your tuition fee.</li>
                      <li> Collect 100% fee from parent directly.</li>
                      <li>Top position in search results</li>
                      <li>Premium phone/what's up/chat support.</li>
                      <li> Risk free money back guarantee.</li>
                      <li>Up to 50% extra contacts free of cost in case you don't get confirmed tution.</li>
                    </ul>
                  </Typography>
                </Box>
              </Box>
        </Box>


      </Grid>

      {/* Section4 */}
      <Grid container sx={{ backgroundColor: '#CB8B65', display: 'flex', marginTop: isXs ? '4%' : "15%" }}>

        <div className="py-4 d-flex justify-content-center"
          style={{
            color: "white",
            fontWeight: "bold",
            fontSize: "28px",
            width: "100%",
            marginTop: isXs ? '3%' : '12%',
            margin: '0 auto'
          }}
        >
          Why Choose Our Tutors?
        </div>

        <Grid container sx={{ display: 'flex', justifyContent: isXs ? 'space-evenly' : 'center', alignItems: isXs ? '' : 'center', marginTop: isXs ? '1%' : '20%' }}>

          <Grid item xs={10} sm={10} md={3} lg={3} xl={3} xxl={3} sx={{ position: 'relative' }} >
            <div
              className="rounded-5"
              style={{
                backgroundColor: "white",
                padding: "20px",
                position: "relative",
              }}
            >
              <div>
                <img
                  src={Leaderstyle}
                  style={{
                    width: "30%",
                    position: "absolute",
                    top: "-20%",
                  }}
                />
              </div>
              <div className="fw-bolder fs-5 py-2" style={{ marginTop: "15%" }}>
                Proven Expertise:
              </div>
              <div>
                Each tutor is rigorously selected based on their knowledge,
                teaching experience, and ability to adapt to different learning
                styles and needs.
              </div>
              <div style={{ marginBottom: "15%" }}>
                <img
                  src={Pin1}
                  style={{
                    position: "absolute",
                    bottom: "-10%",
                    left: "8%",
                  }}
                />
              </div>
            </div>
            <div className="text-end" style={{ marginTop: "30%" }}>
              <img src={Scale} style={{ display: isXs ? '' : 'none' }} />
            </div>
            <img src={Scale} style={{ position: 'absolute', zIndex: 2, left: '-3rem', bottom: '1rem', width: '30%', display: isXs ? "none" : "" }} />
          </Grid>

          <Grid item xs={10} sm={10} md={3} lg={3} xl={3} xxl={3} sx={{ marginTop: '5%' }}>

            <div
              className="rounded-5"
              style={{
                backgroundColor: "white",
                padding: "20px",
                position: "relative",
              }}
            >
              <div>
                <img
                  src={Pin2}
                  style={{
                    position: "absolute",
                    top: "-12%",
                    left: "8%",
                  }}
                />
              </div>
              <div>
                <img
                  src={Leaderstyle2}
                  style={{
                    width: "30%",
                    position: "absolute",
                    top: "-18%",
                    right: "36%",
                  }}
                />
              </div>
              <div className="fw-bolder fs-5 py-2" style={{ marginTop: "15%" }}>
                Customized Approach:
              </div>
              <div style={{ marginBottom: "15%" }}>
                Our tutors understand that each student is unique. They
                customize their teaching methods to match individual learning
                paces and preferences, ensuring optimal comprehension and
                retention.
              </div>
            </div>
          </Grid>

          <Grid item xs={10} sm={10} md={3} lg={3} xl={3} xxl={3} sx={{ position: 'relative', marginTop: isXs ? '' : '30%', marginBottom: isXs ? '' : '20%' }}>
            <div
              className="rounded-5"
              style={{
                backgroundColor: "white",
                padding: "20px",
                position: "relative",
              }}
            >
              <div>
                <img
                  src={Leaderstyle3}
                  style={{
                    width: "30%",
                    position: "absolute",
                    top: "-20%",
                    right: "8%",
                  }}
                />
              </div>
              <div className="fw-bolder fs-5 py-2" style={{ marginTop: "15%" }}>
                Results-Oriented:
              </div>
              <div>
                Focused on achieving tangible academic improvements, our tutors
                are committed to helping each student reach and surpass their
                academic goals.
              </div>
              <div style={{ marginBottom: "15%" }}>
                <img
                  src={Pin3}
                  style={{
                    position: "absolute",
                    bottom: "-9%",
                    right: "8%",
                  }}
                />
              </div>
            </div>
            <div
              className="col-lg-12 d-flex justify-content-end"
              style={{ backgroundColor: "#CB8B65" }}
            >
              <img
                src={Frame}
                style={{
                  width: "40%",
                  position: "absolute",
                  right: "-25%",
                  marginTop: "30%",
                  display: isXs ? '' : 'none'
                }}
              />
              <img src={Frame} style={{ position: 'absolute', zIndex: 2, right: '-2rem', width: '30%', display: isXs ? "none" : "" }} />
            </div>
          </Grid>

        </Grid>

      </Grid>

      {/* Section5 */}
      <Grid container sx={sxStyle}>

        <div
          className="text-center"
          style={{
            marginTop: isXs ? "5%" : '10%',
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: isXs ? "1.6vw" : '4.5vw',
              fontWeight: "bold",
              width: isXs ? "60%" : '70%',
            }}
          >
            How Our Kanha Home Tutions Service Simplifies Your Search for the
            Perfect Tutor
          </div>
          <img
            src={line3}
            style={{
              width: "30%",
              maxWidth: "200px",
              margin: "auto",
              marginTop: isXs ? "1rem" : "2rem",
            }}
          />
        </div>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} sx={sxStyle}>
          <div
            style={{
              marginTop: isXs ? "5%" : "10%",
              backgroundColor: "#F6EBDC",
              width: isXs ? "65%" : '80%',
              height: isXs ? "17.2rem" : '40rem',
              position: "relative",
              borderTopLeftRadius: isXs ? "500px" : '200px',
              borderBottomLeftRadius: isXs ? "500px" : '',
              borderBottomRightRadius: isXs ? "500px" : '',
              borderTopRightRadius: isXs ? "" : '200px',
            }}
          >
            <Grid container >
              <Grid item xs={10} sm={4} md={4} lg={4} xl={4} xxl={4} sx={{ margin: isXs ? '' : '0 auto' }}>
                <img
                  src={Step1}
                  alt="Step1"
                  style={{
                    width: isXs ? "94%" : "100%",
                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                    borderRadius: "50%",
                    marginTop: isXs ? "" : "11%"
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={8} lg={8} xl={8} xxl={8} style={{ display: "flex", alignItems: "center" }} >
                <div style={{ marginRight: isXs ? '8%' : '0 auto' }}>
                  <div className="d-flex fw-bold" style={{ fontSize: isXs ? "2vw" : '5vw', justifyContent: isXs ? '' : "center" }}>
                    <Grid container justifyContent={isXs ? 'flex-start' : 'center'} sx={{ gap: isXs ? '0.5rem' : '' }}>
                      <Grid item xs={10} sm={2} md={2} lg={2} xl={2} xxl={2}>
                        <div style={{ color: "#CB8B65", textAlign: isXs ? "" : 'center', marginTop: isXs ? '' : '11%' }}>
                          Step 1
                        </div>
                      </Grid>
                      <Grid item xs={10} sm={5} md={5} lg={5} xl={5} xxl={5} sx={{ right: '5%' }}>
                        <div style={{ textAlign: isXs ? "" : 'center' }}>Sign Up Easily</div>
                      </Grid>
                    </Grid>
                  </div>
                  <div style={{ fontSize: isXs ? "1vw" : "4vw", textAlign: isXs ? '' : 'center', width: isXs ? "" : "80%", margin: isXs ? '' : '0 auto', marginTop: isXs ? "2%" : "8%" }}>
                    Start by registering on the Kanha Home Tuitions website or app,
                    or simply contact us directly. This step allows you to clarify
                    your specific tutoring needs and preferences, ensuring we
                    connect you with the ideal candidates.
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} sx={sxStyle} style={{ display: isXs ? '' : 'none' }}>
          <div
            className="d-flex align-items-center py-3"
            style={{
              marginTop: "5%",
              backgroundColor: "#CB8B65",
              width: isXs ? "65%" : '80%',
              height: isXs ? "17.2rem" : '30rem',
              position: "relative",
              borderTopLeftRadius: isXs ? "" : '200px',
              borderBottomLeftRadius: isXs ? "500px" : '',
              borderBottomRightRadius: isXs ? "500px" : '',
              borderTopRightRadius: isXs ? "500px" : '200px'
            }}
          >
            <Grid container sx={{ display: 'flex', justifyContent: isXs ? 'flex-end' : '' }}>
              <Grid item xs={10} sm={7} md={7} lg={7} xl={7} xxl={7} style={{ display: "flex", alignItems: "center" }}>
                <div>
                  <div className="d-flex fw-bold" style={{ fontSize: "2vw", gap: '1rem' }}>
                    <div style={{ color: "#FFDB7E" }}>Step 2 </div>
                    <div style={{ color: "white", width: "80%" }}>
                      Post Tutoring Requirements
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: "1vw",
                      color: "white",
                      width: "90%",
                      marginTop: isXs ? '2%' : ''
                    }}
                  >
                    Create a detailed post outlining your child’s academic needs,
                    preferred schedule, and any special considerations. You can also
                    browse posts from our extensive network of qualified tutors to
                    find the right match.
                  </div>
                </div>
              </Grid>
              <Grid item xs={10} sm={4} md={4} lg={4} xl={4} xxl={4} sx={{ display: 'flex', justifyContent: 'flex-end', }}>
                <img
                  src={Step2}
                  alt="Step2"
                  style={{
                    width: isXs ? "95%" : "120%",
                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                    borderRadius: "50%"
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} sx={sxStyle} style={{ display: isXs ? 'none' : '', marginTop: '5%' }}>
          <div
            style={{
              marginTop: "5%",
              backgroundColor: "#CB8B65",
              width: isXs ? "65%" : '80%',
              height: isXs ? "17.2rem" : '40rem',
              position: "relative",
              borderTopLeftRadius: isXs ? "500px" : '200px',
              borderBottomLeftRadius: isXs ? "500px" : '',
              borderBottomRightRadius: isXs ? "500px" : '',
              borderTopRightRadius: isXs ? "" : '200px'
            }}
          >
            <Grid container>
              <Grid item xs={10} sm={5} md={5} lg={5} xl={5} xxl={5} sx={{ margin: isXs ? '' : '0 auto' }}>
                <img
                  src={Step2}
                  alt="Step2"
                  style={{
                    width: isXs ? "93%" : "100%",
                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                    borderRadius: "50%",
                    marginTop: isXs ? "" : "11%"
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={7} lg={7} xl={7} xxl={7} style={{ display: "flex", alignItems: "center" }}>
                <div style={{ margin: isXs ? '' : '0 auto' }}>
                  <div className="d-flex fw-bold" style={{ fontSize: isXs ? "2vw" : '5vw', justifyContent: isXs ? '' : "center" }}>
                    <Grid container justifyContent={isXs ? 'flex-start' : 'center'} >
                      <Grid item xs={10} sm={2.5} md={2.5} lg={2.5} xl={2.5} xxl={2.5}>
                        <div style={{ color: "#FFDB7E", marginRight: "0.5rem", textAlign: isXs ? "" : 'center', marginTop: isXs ? '' : '11%' }}>
                          Step 2
                        </div>
                      </Grid>
                      <Grid item xs={10} sm={5} md={5} lg={5} xl={5} xxl={5} sx={{ right: '5%' }}>
                        <div style={{ textAlign: isXs ? "" : 'center', color: 'white' }}>Post Tutoring Requirements</div>
                      </Grid>
                    </Grid>
                  </div>
                  <div style={{ fontSize: isXs ? "1vw" : "4vw", color: 'white', textAlign: isXs ? '' : 'center', width: isXs ? "" : "80%", margin: isXs ? '' : '0 auto', marginTop: isXs ? "" : "8%" }}>
                    Create a detailed post outlining your child’s academic needs,
                    preferred schedule, and any special considerations. You can also
                    browse posts from our extensive network of qualified tutors to
                    find the right match.
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} sx={sxStyle} style={{ marginTop: '5%' }}>
          <div
            style={{
              marginTop: "5%",
              backgroundColor: "#F6EBDC",
              width: isXs ? "65%" : '80%',
              height: isXs ? "17.2rem" : '42rem',
              position: "relative",
              borderTopLeftRadius: isXs ? "500px" : '200px',
              borderBottomLeftRadius: isXs ? "500px" : '',
              borderBottomRightRadius: isXs ? "500px" : '',
              borderTopRightRadius: isXs ? "" : '200px'
            }}
          >
            <Grid container>
              <Grid item xs={10} sm={4} md={4} lg={4} xl={4} xxl={4} sx={{ margin: isXs ? '' : '0 auto' }}>
                <img
                  src={Step3}
                  alt="Step3"
                  style={{
                    width: isXs ? "94%" : "100%",
                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                    borderRadius: "50%",
                    marginTop: isXs ? "" : "11%"
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={8} lg={8} xl={8} xxl={8} style={{ display: "flex", alignItems: "center" }}>
                <div style={{ margin: isXs ? '' : '0 auto' }}>
                  <div className="d-flex fw-bold" style={{ fontSize: isXs ? "2vw" : '5vw', justifyContent: isXs ? '' : "center" }}>
                    <Grid container justifyContent={isXs ? 'flex-start' : 'center'} sx={{ gap: isXs ? '0.5rem' : '' }}>
                      <Grid item xs={10} sm={2} md={2} lg={2} xl={2} xxl={2}>
                        <div style={{ color: "#CB8B65", textAlign: isXs ? "" : 'center', marginTop: isXs ? '' : '11%' }}>
                          Step 3
                        </div>
                      </Grid>
                      <Grid item xs={10} sm={8} md={8} lg={8} xl={8} xxl={8} sx={{ right: '5%' }}>
                        <div style={{ textAlign: isXs ? "" : 'center' }}>Choose the Ideal Tutor</div>
                      </Grid>
                    </Grid>
                  </div>
                  <div style={{ fontSize: isXs ? "1vw" : "4vw", textAlign: isXs ? '' : 'center', width: isXs ? "95%" : "80%", margin: isXs ? '' : '0 auto', marginTop: isXs ? "2%" : "8%" }}>
                    After posting your requirements, you'll receive responses from
                    our vetted tutors. Arrange a demo class to assess the tutor’s
                    compatibility with your child’s learning style and needs. Once
                    satisfied, you can hire the tutor and discuss any further
                    details directly.
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>

      </Grid>

      {/* Section6 */}
      <Grid container spacing={4} sx={{ backgroundColor: isXs ? '' : '#CB8B65', position: 'relative', marginTop: '10%', paddingBottom: isXs ? '' : '20%' }}>
        <img src={Frame24} alt="Frame24"
          style={{
            position: "absolute",
            width: "100%",
            height: "auto",
            zIndex: -2,
          }}
        />
        <img src={Group221} alt="Group221"
          style={{
            position: "absolute",
            top: "4.5rem",
            left: '3.3rem',
            width: isXs ? "96%" : '0%',
            height: "auto",
            zIndex: -1,
          }}
        />
        <Grid item>
          <div
            className="container-fluid"
            style={{
              position: "relative",
              overflow: "hidden",
            }}
          >
            <div
              className="text-center"
              style={{
                marginTop: isXs ? "6%" : '1%',
                height: isXs ? '20rem' : '',
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontSize: isXs ? "2.2vw" : '6.6vw',
                  fontWeight: "bold",
                  width: isXs ? "55%" : "90%",
                  color: "white",
                }}
              >
                Kickstart Your Child’s Academic Success Today!
              </div>
              <div
                style={{
                  fontSize: isXs ? "1.2vw" : "4vw",
                  width: isXs ? "70%" : "90%",
                  color: "white",
                  marginTop: isXs ? "3%" : "8%",
                  textAlign: isXs ? '' : 'center'
                }}
              >
                Forget the hassle of endless searching for 'tuition near me'. With
                Kanha Home Tutions, discover a personalized, efficient, and reliable
                approach to finding the perfect tutor for your child. Join our
                community of satisfied parents and witness your child's educational
                transformation.
              </div>
              <div
                className="d-flex justify-content-evenly"
                style={{
                  width: isXs ? "60%" : "85%",

                  marginTop: isXs ? "4%" : "10%",
                  fontSize: "1.4vw",
                }}
              >
                <button className="py-2"
                  type="button"
                  onClick={handleOpen}
                  style={{
                    backgroundColor: "#FFDB7E",
                    color: "#866D2C",
                    border: "none",
                    borderRadius: "3px",
                    width: isXs ? "40%" : "90%",
                    fontWeight: "bold",
                    fontSize: isXs ? 'initial' : '5vw',
                    boxShadow: '5px  5px 2px rgb(0,0,0, .2)'
                  }}
                >
                  Post Your Requirments
                </button>
                {/* <div style={{ color: 'white' }}> | </div>
                        <button style={{ backgroundColor: '#FFDB7E', color: '#866D2C', border: 'none', borderRadius: '3px', width: '40%', fontWeight: 'bold' }}>Contact Us</button> */}
              </div>
              <img src={Globe} style={{ position: 'absolute', zIndex: 2, right: '-1rem', top: '1rem', width: '12%', display: isXs ? "none" : "" }} />
            </div>
          </div>
          <img src={NoteBook} style={{ position: 'absolute', zIndex: 2, left: '3rem', bottom: '-1rem', width: '15%', display: isXs ? "none" : "" }} />
        </Grid>
      </Grid>

      {/* Section7 Web */}
      <Grid container sx={{ display: isXs ? '' : 'none', marginTop: '5%', display: isXs ? "" : "none" }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
          <div
            className="d-flex justify-content-center"
            style={{
              position: "relative",
              marginTop: "10%",
              marginBottom: "10%",
            }}
          >
            <img
              src={Frame25}
              alt="Frame24"
              style={{
                position: "absolute",
                width: "78%",
                height: "auto",
                zIndex: 2,
                left: "8.5rem",
                top: "-3rem",
              }}
            />
            <div
              className="d-flex align-items-center"
              style={{
                borderBottomRightRadius: "10px",
                height: "20rem",
                width: "80%",
                backgroundColor: "#F6EBDC",
                borderTopRightRadius: "150px",
                borderBottomLeftRadius: "150px",
              }}
            >
              <div
                style={{ 
                  maxWidth: "40%",
                  marginLeft: "6vw",
                  marginBottom: "5%",
                }}
              >
                {/* <div style={{ fontSize: "2.4vw", fontWeight: "bold" }}>
                  Kanha Home Tutions
                </div> */}
                <div style={{ fontSize: "1.5vw", width: "90%" }}>
                Download the Kanha Home Tuitions Student and Tutor apps now on Google Play Store!
                </div>
                {/* <div>
                            <button style={{ border: 'none', width: '40%', maxWidth: '200px', marginRight: '2%', fontSize: '3vw' }}><img src={GooglePlay} style={{ width: '100%' }} alt="Google Play" /></button>
                            <button style={{ border: 'none', width: '40%', maxWidth: '200px', fontSize: '3vw' }}><img src={AppStore} style={{ width: '100%' }} alt="App Store" /></button>
                        </div> */}
              </div>
            </div>
          </div>
        </Grid>
      </Grid>

      {/* Section7 Mobile */}
      <Grid container sx={{ display: isXs ? 'none' : '', marginTop: '5%', display: isXs ? "none" : "" }} >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} sx={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
          <img
            src={GroupPhone}
            alt="GroupPhone"
            style={{
              position: "absolute",
              width: "50%",
              height: "auto",
              zIndex: 1
            }}
          />
          <img src={PhoneDoodlePlus} style={{ position: 'absolute', zIndex: 2, left: '3rem', top: '7rem' }} />
          <img src={PhoneDoodleMultiply} style={{ position: 'absolute', zIndex: 2, right: '3rem', top: '8rem' }} />

        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
          <div
            className="d-flex justify-content-center"
            style={{
              position: "relative",
              marginTop: "20%",
              marginBottom: "10%",
            }}>
            <div
              className="d-flex align-items-center"
              style={{
                borderBottomRightRadius: "10px",
                height: "25rem",
                width: "90%",
                
                backgroundColor: "#F6EBDC",
                borderTopRightRadius: "50px",
                borderBottomLeftRadius: "50px",
                display: 'flex', justifyContent: 'center'
              }}
            >
              <div
                style={{
                  minWidth: '100%',
                  marginTop: '45%',
                  marginBottom: "5%",
                }}
              >
                
                <div style={{ fontSize: "4.2vw", width: "80%", margin: '0 auto', marginTop: '2%', textAlign: 'center', marginBottom: '15%' }}>
                Download the Kanha Home Tuitions Student and Tutor apps now on Google Play Store!
                </div>
                <Grid item xs={10} sm={3} md={3} lg={3} xl={3} xxl={3} spacing={3} sx={{ margin: '0 auto' }}>
                  {/* <div style={{ marginTop: isXs ? "10%" : "18%", minWidth: '100%', textAlign: 'center' }}>
                    <a href="">
                      <img src={GooglePlay} alt="GooglePlay" width="70%" />
                    </a>
                    <a href="">
                      <img
                        src={AppStore}
                        alt="AppStore"
                        width="70%"
                        style={{ marginTop: "10%" }}
                      />
                    </a>
                  </div> */}
                </Grid>
                <img src={PhoneDoodleBook} style={{ position: 'absolute', zIndex: 2, left: '4rem', bottom: '2rem' }} />
                <img src={PhoneDoodleDivide} style={{ position: 'absolute', zIndex: 2, right: '3rem', bottom: '1rem' }} />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>

      <Box >
          <Button onClick={() => {navigate('/accountstudent')}} sx={{color:'white'}}>abc</Button>
          <Button onClick={() => {navigate('/accountteacher')}} sx={{color:'white'}}>xyz</Button>
      </Box>

      <Footer />
    </>
  );
};

const Backdrop = React.forwardRef((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ "base-Backdrop-open": open }, className)}
      ref={ref}
      {...other}
    />
  );
});

Backdrop.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool,
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-content {
    background-color: white; 
    border-radius: 14px; 
  }
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

export default Landing;
