import {
    createSlice,
    createAsyncThunk,
    isRejectedWithValue,
} from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/axios";

export const signUp = createAsyncThunk(
    "teacher/signUp",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axiosInstance.post("teacherSignup", data);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);

export const verifyOtpTeacher = createAsyncThunk(
    "teacher/verifyOtp",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axiosInstance.post("teacherVerifyOtp", data);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);

export const signIn = createAsyncThunk(
    "teacher/signIp",
    async (data, { rejectedWithValue }) => {
        try {
            const response = await axiosInstance.post("teacherSignin", data);
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectedWithValue(error.response.data);
        }
    }
);




// const user = JSON.parse(localStorage.getItem('user'))
// const idUser = user?.id


const initialState = {
    signUpResponseData: '',
    teacherMobileNumber:'',
    // userAuthDeatils:'',
    loading: false,
    error: null,

};


export const teacherAuthSlice = createSlice({
    name: "teacher",
    initialState,
    reducers: {
        storeUpdateNumber: (state, action) => {
            console.log('sdfaaaa', action.payload);
            state.teacherMobileNumber = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(signUp.pending, (state) => {
                state.loading = true;
            })
            .addCase(signUp.fulfilled, (state, action) => {
                state.loading = false;
                state.signUpResponseData = action.payload.data;
                state.error = null;
            })
            .addCase(signUp.rejected, (state, action) => {
                state.loading = false;
                state.citiesListing = null;
                state.error = action.payload;
            })
            .addCase(verifyOtpTeacher.pending, (state) => {
                state.loading = true;
            })
            .addCase(verifyOtpTeacher.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
            })
            .addCase(verifyOtpTeacher.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(signIn.pending, (state) => {
                state.loading = true;
            })
            .addCase(signIn.fulfilled, (state, action) => {
                state.loading = false;
                state.signUpResponseData = action.payload.data;
                state.error = null;
            })
            .addCase(signIn.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
    },
});


export const {
    storeUpdateNumber
} = teacherAuthSlice.actions;

export default teacherAuthSlice.reducer;
