import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import PrivacyPolicy from './TermsAndPrivacy/PrivacyPolicy'
import TermsAndConditions from './TermsAndPrivacy/TermsAndConditions'
import AccountDeleteStudent from './AccountStudent/AccountDeleteStudent'
import AccountDeleteTeacher from './AccountTeacher/AccountDeleteTeacher'

import Landing from './LandingPage/Landing'
import NotFound from './AccountTeacher/NotFound'
import RefundPolicy from './TermsAndPrivacy/RefundPolicy'
import PrivacyPolicyMobile from './TermsAndPrivacy/PrivacyPolicyMobile'
import RefundPolicyMobile from './TermsAndPrivacy/RefundPolicyMobile'
import TermsAndConditionsMobile from './TermsAndPrivacy/TermsAndConditionsMobile'

const routing = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' exact element={<Landing />}></Route>
          <Route path='/privacy-policy' exact element={<PrivacyPolicy />}></Route>
          <Route path='/privacy-policy-mobile' exact element={<PrivacyPolicyMobile />}></Route>
          <Route path='/refund-policy' exact element={<RefundPolicy />}></Route>
          <Route path='/refund-policy-mobile' exact element={<RefundPolicyMobile />}></Route>
          <Route path='/terms-conditions' exact element={<TermsAndConditions />}></Route>
          <Route path='/terms-conditions-mobile' exact element={<TermsAndConditionsMobile />}></Route>
          <Route path='/deleteStudentAccount'  element={<AccountDeleteStudent />}></Route>
          <Route path='/deleteTutorAccount'  element={<AccountDeleteTeacher />}></Route>
          <Route path="*" element={<NotFound />} /> 
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default routing
