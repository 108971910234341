import logo from "../../../assets/TClogo.png";
import { Grid, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import FooterBack from "../../../assets/FooterBack.svg";
import LogoFooter from "../../../assets/LogoFooter.svg";
import location2 from "../../../assets/location2.svg";
import Phone from "../../../assets/Phone.svg";
import Email from "../../../assets/Email.svg";
import GooglePlay from "../../../assets/GooglePlay.svg";
import AppStore from "../../../assets/AppStore.svg";
import Footer from "../LandingPage/Footer";
import { Link, useNavigate } from 'react-router-dom';



function RefundPolicyMobile() {
    const isXs = useMediaQuery('(min-width:600px)');
    const navigate = useNavigate();
    const navigateLanding = () => {
        navigate('/')
    }

    return (
        <>
            <Box style={{ width: '100%' }}>
                <Box style={{ width: '100%' }}>
                  

                    <Box style={{ padding:isXs? '2rem':'1rem', display: 'flex', flexDirection: 'column', gap: '1.3rem', width: isXs?'70%':'100%' }}>
                        <Box>
                            <h1 style={{ fontSize: '1.2rem', fontWeight: 600 }}>Refund Policy for Kanha Home Tutions</h1>
                        </Box>
                        <Box>
                            <p>
                            Please note that payments made through our Tutor App are non-refundable. We do not offer refunds under any circumstances. All transactions are final.
                            </p>
                        </Box>
                        <Box>
                            <h1 style={{ fontSize: '1.2rem', fontWeight: 600 }}>1. Final Sale</h1>
                        </Box>
                        {/* <Box>
                            <h1 style={{ fontSize: '1.2rem', fontWeight: 600 }}>Personal Information</h1>
                        </Box> */}
                        <Box>
                            <p>
                            All payments for services, subscriptions, or any in-app purchases are considered final and non-refundable.
                            </p>
                        </Box>
                        {/* <Box style={{ padding: '0rem 1rem', lineHeight: '2rem' }}>
                            <ul>
                                <li>All payments for services, subscriptions, or any in-app purchases are considered final and non-refundable.</li>
                                <li>Email Address</li>
                                <li>Phone Number</li>
                                <li>Address</li>
                                <li>Payment Information</li>
                                <li>Aadhaar Card (for verification purposes)</li>
                                <li>
                                    Profile Information (including educational background and
                                    teaching experience for tutors)
                                </li>
                            </ul>
                        </Box> */}

                        {/* <Box>
                            <h1 style={{ fontSize: '1.2rem', fontWeight: 600 }}>Usage Data</h1>
                        </Box>
                        <Box>
                            <p>
                                We may also collect information about how the Website is accessed
                                and used. This usage data may include information such as your IP
                                address, browser type, browser version, the pages of our Website
                                that you visit, the time and date of your visit, the time spent on
                                those pages, and other diagnostic data.
                            </p>
                        </Box> */}
                        <Box>
                            <h1 style={{ fontSize: '1.2rem', fontWeight: 600 }}>2. No Exceptions</h1>
                        </Box>
                        <Box>
                            <p>
                            Refunds will not be issued for unused services, subscription periods, or any other reason.
                            </p>
                        </Box>
                        {/* <Box style={{ padding: '0rem 1rem', lineHeight: '2rem' }}>
                            <ul>
                                <li>Provide, operate, and maintain our Website and services.</li>
                                <li>
                                    Process transactions and send related information, such as
                                    purchase confirmations and invoices.
                                </li>
                                <li>
                                    Verify your identity and process verification payments through
                                    third-party APIs.
                                </li>
                                <li>
                                    Allow students and tutors to communicate and set up classes,
                                    including scheduling and payment arrangements.
                                </li>
                                <li>
                                    Respond to your comments, questions, and requests, and provide
                                    customer service.
                                </li>
                                <li>
                                    Send you technical notices, updates, security alerts, and
                                    support and administrative messages.
                                </li>
                                <li>
                                    Monitor and analyze usage and activities in connection with our
                                    Website.
                                </li>
                                <li>
                                    Personalize and improve our services and provide content or
                                    features that match user profiles or interests.
                                </li>
                                <li>
                                    Process payments to unhide student inquiry details for tutors.
                                </li>
                            </ul>
                        </Box> */}
                        <Box>
                            <h1 style={{ fontSize: '1.2rem', fontWeight: 600 }}>3. Service Interruptions</h1>
                        </Box>
                        <Box>
                            <p>Payments will not be refunded in case of temporary service interruptions or technical issues. We are committed to resolving any issues promptly.</p>
                        </Box>
                        {/* <Box style={{ padding: '0rem 1rem', lineHeight: '2rem' }}>
                            <ul>
                                <li>
                                    With Service Providers: We may share your information with
                                    third-party service providers to perform certain services on our
                                    behalf, such as payment processing and verification services.
                                </li>
                                <li>
                                    With Other Users: When students and tutors use our services,
                                    they may share certain information with each other to facilitate
                                    communication and scheduling.
                                </li>
                                <li>
                                    For Legal Reasons: We may disclose your information if required
                                    to do so by law or in response to valid requests by public
                                    authorities.
                                </li>
                            </ul>
                        </Box> */}

                        <Box>
                            <h1 style={{ fontSize: '1.2rem', fontWeight: 600 }}>4. Dispute Resolution</h1>
                        </Box>
                        <Box>
                            <p>
                            In case of any disputes, please contact our support team. We aim to address all concerns and provide assistance to ensure a satisfactory resolution.
                            For any queries or issues, please contact our support team for assistance.
                            </p>
                        </Box>
<Box style={{ lineHeight: '2rem' }}>
    <p> Contact Information:</p>
                            <ul>
                                {/* <li>
                                  Contact Information:
                                </li> */}
                                <li>Email: kanhahometutions@gmail.com
                                </li>
                                <li>
                                  Helpline No.: +91-9166100755
                                </li>
                            </ul>
                        </Box>
                        
                    </Box>
                    <Box style={{ marginTop: '2rem', textAlign: 'center', fontWeight: 600 }}>
                        <p>
                            By using the Website and our services, you consent to the terms of this Refund Policy.
                        </p>
                    </Box>
                </Box>
            </Box>
        </>
    );
}

export default RefundPolicyMobile;
