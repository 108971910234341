import logo from "../../../assets/TClogo.png";
import { Grid, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import FooterBack from "../../../assets/FooterBack.svg";
import LogoFooter from "../../../assets/LogoFooter.svg";
import location2 from "../../../assets/location2.svg";
import Phone from "../../../assets/Phone.svg";
import Email from "../../../assets/Email.svg";
import GooglePlay from "../../../assets/GooglePlay.svg";
import AppStore from "../../../assets/AppStore.svg";
import Footer from "../LandingPage/Footer";
import { Link, useNavigate } from 'react-router-dom';

function Privacy() {
    const isXs = useMediaQuery('(min-width:600px)');
    const navigate = useNavigate();
    const navigateLanding = () => {
        navigate('/')
    }

    return (
        <>
            <Box style={{ width: '100%' }}>
                <Box style={{ width: '100%' }}>
                    <Box style={{ width: '100%', height: '12rem', backgroundColor: '#CB8B65', padding: isXs? '2rem': '1rem', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <button  onClick={navigateLanding} style={{backgroundColor: "transparent" , border: "none" , textAlign: "left"}} ><img src={logo} alt="" width={200} /></button>   
                        <h1 style={{ fontSize: '2rem', color: 'white', textTransform: 'uppercase' }}>Privacy Policy</h1>
                    </Box>

                    <Box style={{ padding:isXs? '2rem':'1rem', display: 'flex', flexDirection: 'column', gap: '1.3rem', width: isXs?'70%':'100%' }}>
                        <Box>
                            <h1 style={{ fontSize: '1.2rem', fontWeight: 600 }}>Privacy Policy for Kanha Home Tutions</h1>
                        </Box>
                        <Box>
                            <p>
                                Welcome to Kanha Home Tutions ("we," "our," or "us"). We are
                                committed to protecting your privacy. This Privacy Policy explains
                                how we collect, use, disclose, and safeguard your information when
                                you visit our website <Link to={"https://www.kanhahometutions.com/"}>kanhahometutions.com</Link>  and use
                                our services.
                            </p>
                        </Box>
                        <Box>
                            <h1 style={{ fontSize: '1.2rem', fontWeight: 600 }}>1. Information We Collect</h1>
                        </Box>
                        <Box>
                            <h1 style={{ fontSize: '1.2rem', fontWeight: 600 }}>Personal Information</h1>
                        </Box>
                        <Box>
                            <p>
                                We may collect personal information that you voluntarily provide
                                to us when you register on the Website, such as:
                            </p>
                        </Box>
                        <Box style={{ padding: '0rem 1rem', lineHeight: '2rem' }}>
                            <ul>
                                <li>Name</li>
                                <li>Email Address</li>
                                <li>Phone Number</li>
                                <li>Address</li>
                                <li>Payment Information</li>
                                <li>Aadhaar Card (for verification purposes)</li>
                                <li>
                                    Profile Information (including educational background and
                                    teaching experience for tutors)
                                </li>
                            </ul>
                        </Box>

                        <Box>
                            <h1 style={{ fontSize: '1.2rem', fontWeight: 600 }}>Usage Data</h1>
                        </Box>
                        <Box>
                            <p>
                                We may also collect information about how the Website is accessed
                                and used. This usage data may include information such as your IP
                                address, browser type, browser version, the pages of our Website
                                that you visit, the time and date of your visit, the time spent on
                                those pages, and other diagnostic data.
                            </p>
                        </Box>
                        <Box>
                            <h1 style={{ fontSize: '1.2rem', fontWeight: 600 }}>2. How We Use Your Information</h1>
                        </Box>
                        <Box>
                            <p>
                                We use the information we collect in various ways, including to:
                            </p>
                        </Box>
                        <Box style={{ padding: '0rem 1rem', lineHeight: '2rem' }}>
                            <ul>
                                <li>Provide, operate, and maintain our Website and services.</li>
                                <li>
                                    Process transactions and send related information, such as
                                    purchase confirmations and invoices.
                                </li>
                                <li>
                                    Verify your identity and process verification payments through
                                    third-party APIs.
                                </li>
                                <li>
                                    Allow students and tutors to communicate and set up classes,
                                    including scheduling and payment arrangements.
                                </li>
                                <li>
                                    Respond to your comments, questions, and requests, and provide
                                    customer service.
                                </li>
                                <li>
                                    Send you technical notices, updates, security alerts, and
                                    support and administrative messages.
                                </li>
                                <li>
                                    Monitor and analyze usage and activities in connection with our
                                    Website.
                                </li>
                                <li>
                                    Personalize and improve our services and provide content or
                                    features that match user profiles or interests.
                                </li>
                                <li>
                                    Process payments to unhide student inquiry details for tutors.
                                </li>
                            </ul>
                        </Box>
                        <Box>
                            <h1 style={{ fontSize: '1.2rem', fontWeight: 600 }}>3. Sharing Your Information</h1>
                        </Box>
                        <Box>
                            <p>We may share your information in the following situations:</p>
                        </Box>
                        <Box style={{ padding: '0rem 1rem', lineHeight: '2rem' }}>
                            <ul>
                                <li>
                                    With Service Providers: We may share your information with
                                    third-party service providers to perform certain services on our
                                    behalf, such as payment processing and verification services.
                                </li>
                                <li>
                                    With Other Users: When students and tutors use our services,
                                    they may share certain information with each other to facilitate
                                    communication and scheduling.
                                </li>
                                <li>
                                    For Legal Reasons: We may disclose your information if required
                                    to do so by law or in response to valid requests by public
                                    authorities.
                                </li>
                            </ul>
                        </Box>

                        <Box>
                            <h1 style={{ fontSize: '1.2rem', fontWeight: 600 }}>4. Third-Party Services</h1>
                        </Box>
                        <Box>
                            <p>
                                Our Website may contain links to third-party websites or services
                                that are not owned or controlled by us. We have no control over,
                                and assume no responsibility for, the content, privacy policies,
                                or practices of any third-party websites or services. We encourage
                                you to review the privacy policies of any third-party websites you
                                visit.
                            </p>
                        </Box>

                        <Box>
                            <h1 style={{ fontSize: '1.2rem', fontWeight: 600 }}>5. Data Security</h1>
                        </Box>
                        <Box>
                            <p>
                                We implement reasonable security measures designed to protect your
                                personal information from unauthorized access, use, or disclosure.
                                However, no method of transmission over the internet or method of
                                electronic storage is 100% secure, and we cannot guarantee its
                                absolute security.
                            </p>
                        </Box>

                        <Box>
                            <h1 style={{ fontSize: '1.2rem', fontWeight: 600 }}>6. Data retention and deletion</h1>
                        </Box>
                        <Box>
                            <p>
                            Users may request deletion of their account through the Privacy menus in the Kanha Home Tutions app, or through Kanha Home Tutions’s website (students <Link to={"/deleteStudentAccount"}>here</Link>; teachers <Link to={"/deleteTutorAccount"}> here </Link>;).
                            </p>
                            <p>
                            Following an account deletion request, we delete the user’s account and data, except as necessary for purposes of safety, security, fraud prevention or compliance with legal requirements, or because of issues relating to the user’s account.
                            </p>
                        </Box>
                        {/*  */}
                        <Box>
                            <h1 style={{ fontSize: '1.2rem', fontWeight: 600 }}>7. Your Rights and Choices</h1>
                        </Box>
                        <Box>
                            <p>
                                You have the right to access, correct, update, or delete your
                                personal information. You can do this by logging into your account
                                or by contacting us at <u >kanhahometutions@gmail.com</u>
                                . You also have the
                                right to opt-out of receiving marketing communications from us.
                            </p>
                        </Box>
                        {/*  */}


                        <Box>
                            <h1 style={{ fontSize: '1.2rem', fontWeight: 600 }}>8. Changes to This Privacy Policy</h1>
                        </Box>
                        <Box>
                            <p>
                                We may update our Privacy Policy from time to time. We will notify
                                you of any changes by posting the new Privacy Policy on this page.
                                You are advised to review this Privacy Policy periodically for any
                                changes.
                            </p>
                        </Box>
                    </Box>
                    <Box style={{ marginTop: '2rem', textAlign: 'center', fontWeight: 600 }}>
                        <p>
                            By using the Website and our services, you consent to the terms of this Privacy Policy.
                        </p>
                    </Box>
                </Box>
            </Box>
           <Footer/>
        </>
    );
}

export default Privacy;
