import axios from "axios";

const EndPoint = 'https://app.kanhahometutions.com/api/v1';
// const EndPoint = 'http://192.168.0.9:8181/api/v1';

const axiosInstance = axios.create({
    timeout: 1000000,
    baseURL: EndPoint,
});

// instance.interceptors.request.use(config => {
//     const user = JSON.parse(localStorage.getItem('user'))
//     const token = user.accessToken
//     if(token){
//         config.headers['Authorization'] = 'Bearer ' + token;
//     }
//     return config;
// });

// instance.interceptors.request.use(config => {
//   // const user = JSON.parse(localStorage.getItem('user'))
//   //     const token = user.accessToken
//   //     if(token){
//   //         config.headers['Authorization'] = 'Bearer ' + token;
//   //     }
//   //     return config
// });

axiosInstance.interceptors.request.use(function (config) {
  // Do something before request is sent
   const user = JSON.parse(localStorage.getItem('user'))
   if(user){
    const token = user.accessToken
    if(token){
        config.headers['Authorization'] = 'Bearer ' + token;
    }
   }
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});


export default axiosInstance;
