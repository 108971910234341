import logo from "../../../assets/TClogo.png";
import { Grid, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import FooterBack from "../../../assets/FooterBack.svg";
import LogoFooter from "../../../assets/LogoFooter.svg";
import location2 from "../../../assets/location2.svg";
import Phone from "../../../assets/Phone.svg";
import Email from "../../../assets/Email.svg";
import GooglePlay from "../../../assets/GooglePlay.svg";
import AppStore from "../../../assets/AppStore.svg";
import Footer from "../LandingPage/Footer";

function NotFound() {
    const isXs = useMediaQuery('(min-width:600px)');

    return (
        <>
            <Box style={{ width: '100%' }}>
                <Box style={{ width: '100%' }}>
                    <Box style={{ width: '100%', height: '12rem', backgroundColor: '#CB8B65', padding: isXs? '2rem': '1rem', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <img src={logo} alt="" width={200} />
                        {/* <h1 style={{ fontSize: '2rem', color: 'white', textTransform: 'uppercase' }}>Not Found</h1> */}
                    </Box>

                    <Box style={{ padding:isXs? '2rem':'1rem', display: 'flex', flexDirection: 'column', gap: '1.3rem', width: isXs?'70%':'100%' }}>
                    <h1>404 Not Found</h1>
                    <p>The page you are looking for does not exist.</p>
                </Box>
            </Box>
            </Box>
           <Footer/>
        </>
    );
}

export default NotFound;
