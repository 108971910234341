import { combineReducers } from 'redux';
// import studentReducer from '../features/student/studentSlice';
// import stepperReducer from '../features/student/listingSlice';
// import teacherDropDownReducer from '../features/teacher/teacherListingSlice';
// import teacherAuthReducer from '../features/teacher/teacherAuthSlice';
// import teacherClassConductReducer from '../features/teacher/teacherClassConductSlice';
import commonReducer from '../features/common/commonSlice';



export const rootReducer = combineReducers({
    common: commonReducer,
    // listing: stepperReducer,
    // teacherListing: teacherDropDownReducer,
    // teacherSignUp: teacherAuthReducer,
    // teacherSignUp: teacherAuthReducer,
    // teacherClassConduct: teacherClassConductReducer
  });
  