import { configureStore } from "@reduxjs/toolkit";
import { rootReducer } from "./rootReducer";
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import commonReducer from '../features/common/commonSlice';


const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['common'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

export default store;
