import React from "react";
import { Grid, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import FooterBack from "../../../assets/FooterBack.svg";
import LogoFooter from "../../../assets/LogoFooter.svg";
import location2 from "../../../assets/location2.svg";
import Phone from "../../../assets/Phone.svg";
import instagram from "../../../assets/instagram.svg";
import facebook from "../../../assets/facebook.svg";
import twitter from "../../../assets/twitter.svg";
import Email from "../../../assets/Email.svg";
import GooglePlay from "../../../assets/GooglePlay.svg";
import AppStore from "../../../assets/AppStore.svg";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "bootstrap";


const Footer = () => {
  const isXs = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();

  const handleClickfortutor = () => {
    const playStoreUrl =
      "https://play.google.com/store/apps/details?id=com.kanhahometutions.tutor";
    window.open(playStoreUrl, "_blank");
  };

  const handleClickforstudent = () => {
    const playStoreUrl =
      "https://play.google.com/store/apps/details?id=com.kanhahometutions.student";
    window.open(playStoreUrl, "_blank");
  };

  const khtinstagram = () => {
    const playStoreUrl =
      "https://www.instagram.com/kahna_home_tuition?igsh=c29rOWFyY29wdm5m";
    window.open(playStoreUrl, "_blank");
  };

  const khtfacebook = () => {
    const playStoreUrl =
      "https://www.facebook.com/people/Kahna-Home-Tution/pfbid02UT2CFSy7KXJqUsCZXMVs5mLnDoF5v8VNwUCxEApgejQJBjDaBmeSYe2T4hjwwXgVl/?mibextid=ZbWKwL";
    window.open(playStoreUrl, "_blank");
  };

  const khttwitter = () => {
    const playStoreUrl =
      "https://x.com/kanhahometution?s=21";
    window.open(playStoreUrl, "_blank");
  };


  return (
    <>
      {/* Footer */}
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: isXs ? "" : "center",
          backgroundColor: isXs ? "" : "#20140E",
          paddingBottom: isXs ? "" : "15%",
        }}
      >
        <img
          src={FooterBack}
          alt="Frame24"
          style={{
            position: "absolute",
            width: "100%",
            maxHeight: "100%",
            zIndex: -2,
            display: isXs ? "" : "none",
          }}
        />
        <Grid
          item
          xs={10}
          sm={3}
          md={3}
          lg={3}
          xl={3}
          xxl={3}
          spacing={3}
          sx={{ marginTop: "5%" }}
        >
          <Box style={{ marginTop: "7%", display: "flex" }}>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box sx={{ textAlign: "center" }}>
                <img
                  src={LogoFooter}
                  alt="LogoFooter"
                  style={{ width: "70%" }}
                />
              </Box>
              <Box
                style={{
                  width: isXs ? "60%" : "95%",
                  color: "white",
                  fontSize: isXs ? "1vw" : "3.8vw",
                  textAlign: isXs ?  "left" : "center",
                  marginLeft: isXs ? "3.5rem" : "0rem",
                  marginTop: isXs ? "" : "2%",
                }}
              >
                Empower your learning journey with Kanha Home Tuitions—where
                potential meets possibility right at your doorstep!
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={10}
          sm={3}
          md={3}
          lg={3}
          xl={3}
          xxl={3}
          spacing={3}
          sx={{ marginTop: "8%" }}
        >
          <Box
            style={{
              marginTop: "10%",
              fontSize: "1vw",
              minWidth: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{ width: "80%", display: "flex", justifyContent: "center" }}
            >
              <Box sx={{ width: "60%" }}>
                <a href="./landing" style={{ textDecoration: "none" }}>
                  {" "}
                  <button
                    className="d-flex align-items-center"
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      width: "100%",
                      justifyContent: isXs ? "" : "center",
                    }}
                  >
                    <Box
                      style={{
                        color: "white",
                        marginLeft: "5%",
                        marginTop: "5%",
                        fontSize: isXs ? "" : "3.8vw",
                      }}
                    >
                      Home
                    </Box>
                  </button>
                </a>
                <a href="./privacy-policy" style={{ textDecoration: "none" }}>
                  <button
                    className="d-flex align-items-center"
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      width: "100%",
                      justifyContent: isXs ? "" : "center",
                    }}
                  >
                    <Box
                      style={{
                        color: "white",
                        marginLeft: "5%",
                        marginTop: "5%",
                        fontSize: isXs ? "" : "3.8vw",
                      }}
                    >
                      Privacy Policy
                    </Box>
                  </button>
                </a>
                <a href="./refund-policy" style={{ textDecoration: "none" }}>
                  {" "}
                  <button
                    className="d-flex align-items-center"
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      width: "100%",
                      justifyContent: isXs ? "" : "center",
                    }}
                  >
                    <Box
                      style={{
                        color: "white",
                        marginLeft: "5%",
                        marginTop: "5%",
                        fontSize: isXs ? "" : "3.8vw",
                      }}
                    >
                      Refund Policy
                    </Box>
                  </button>
                </a>
                <a href="/terms-conditions" style={{ textDecoration: "none" }}>
                  {" "}
                  <button
                    className="d-flex align-items-center"
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      width: "100%",
                      justifyContent: isXs ? "" : "center",
                    }}
                  >
                    <Box
                      style={{
                        color: "white",
                        marginLeft: "5%",
                        marginTop: "5%",
                        fontSize: isXs ? "" : "3.8vw",
                      }}
                    >
                      Terms & Conditions
                    </Box>
                  </button>
                </a>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={10}
          sm={3}
          md={3}
          lg={3}
          xl={3}
          xxl={3}
          spacing={3}
          sx={{ marginTop: "8%" }}
        >
          <Box
            style={{
              marginRight: "10%",
              marginTop: "10%",
              fontSize: "1vw",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box sx={{display: "flex" , flexDirection: "column" , gap: "1rem"}}>
              <Box className="d-flex align-items-center ">
                <img src={location2} alt="location2" width="8%" />
                <Box
                  style={{
                    color: "white",
                    marginLeft: "5%",
                    minWidth: isXs ? "95%" : "95%",
                    fontSize: isXs ? "" : "3.8vw",
                  }}
                >
                  Plot No. 45, Vaishali Nagar, Jaipur
                </Box>
              </Box>
              <Box className="d-flex align-items-center">
                <img src={Phone} alt="Phone" width="7%" />
                <Box
                  style={{
                    color: "white",
                    marginLeft: "5%",
                    
                    fontSize: isXs ? "" : "3.8vw",
                  }}
                >
                  +91-6376400386
                </Box>
              </Box>
              <Box className="d-flex align-items-center">
                <img src={Email} alt="Email" width="7%" />
                <Box
                  style={{
                    color: "white",
                    marginLeft: "5%",
                    marginTop: "0%",
                    fontSize: isXs ? "" : "3.8vw",
                  }}
                >
                  kanhahometutions@gmail.com
                </Box>
              </Box>
              <Box sx={{display: "flex" , gap: "1rem" , marginTop: "1rem"}}>
              <Box><img onClick={khtinstagram} src={instagram} alt="" /></Box>
              <Box><img onClick={khtfacebook} src={facebook} alt="" /></Box>
              <Box><img onClick={khttwitter} src={twitter} alt="" /></Box>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={10}
          sm={3}
          md={3}
          lg={3}
          xl={3}
          xxl={3}
          spacing={3}
          sx={{ marginTop: "6%" }}
        >
          <Box
            style={{
              marginTop: isXs ? "10%" : "1%",
              minWidth: "100%",
              textAlign: "left",
              display: "flex",
              flexDirection: isXs ? "column" : "row",
              gap: "1rem",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                gap: isXs ? "1rem" : ".5rem",
              }}
            >
              <Box style={{ color: "white" }}>For Tutor</Box>

              <img
                onClick={handleClickfortutor}
                src={GooglePlay}
                alt="GooglePlay"
                style={{ width: isXs ? "60%" : "90%" }}
              />
            </Box>

            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                gap: isXs ? "1rem" : ".5rem",
              }}
            >
              <Box style={{ color: "white" }}>For Learner</Box>

              <img
                onClick={handleClickforstudent}
                src={GooglePlay}
                alt="GooglePlay"
                style={{ width: isXs ? "60%" : "90%" }}
              />
            </Box>
            {/* <Box style= {{color: "white"}}>
                            For Learner
                        </Box>
                        <Link to="https://play.google.com/store/apps/details?id=com.kanhahometutions.tutor">
                        <img src={GooglePlay} alt="GooglePlay" width="50%" />
                        </Link> */}
          </Box>
        </Grid>
      </Grid>

      {/* footer */}
      <Grid
        container
        className="py-3 px-2"
        sx={{
          marginTop: isXs ? "2%" : "",
          backgroundColor: "#A6653F",
          fontSize: isXs ? "1vw" : "3vw",
          color: "white",
        }}
      >
        <Box style={{ textAlign: isXs ? "start" : "center", margin: "0 auto" }}>
          &copy;2024 Kanha Home Tutions. All Right Reserved{" "}
        </Box>
        <Box style={{ textAlign: isXs ? "end" : "center", margin: "0 auto" }}>
          Powered by Infomagine Softwares Pvt. Ltd.
        </Box>
      </Grid>
    </>
  );
};

export default Footer;
